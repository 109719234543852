import Sketch from "react-p5";
import p5Types from "p5"; //Import this for typechecking and intellisense
import { random } from "../core/utils";

const particles: {
  x: number;
  y: number;
  size: number;
  spd: number;
  acc: number;
  stopHeight: number;
}[] = [];

let wind = 0;
let windAcc = 0;
let currentTime = 0;
const snowiness = Math.ceil(Math.random() * 6);

const groundTop_ypos = 100;

function SnowSketchONLYSNOW() {

  const setup = (p5: p5Types, canvasParentRef: Element) => {
    p5.frameRate(30);
    p5.createCanvas(window.innerWidth, window.innerHeight).parent(
      canvasParentRef
    );
  };

  const draw = (p5: p5Types) => {
    if (p5.frameRate()) {
      currentTime += 1.0 / p5.frameRate();
    }

    p5.clear(0,0,0,0)

    particles.forEach((particle) => {
      p5.noStroke();
      p5.ellipse(particle.x, particle.y, particle.size, particle.size);

      if (p5.height - particle.y > particle.stopHeight) {
        particle.y += particle.spd / p5.frameRate();
        particle.spd += particle.acc / p5.frameRate();
        particle.x += wind / Math.sqrt(particle.size);
      }
    });

    if (p5.frameRate()) {
      wind += windAcc / p5.frameRate();
      windAcc = Math.random() - 0.495;
      if (wind > 0.2 || wind < -0.2) {
        wind = 0;
      }
    }

    if (Math.random() < 0.35) {
      for (let i = 0; i < snowiness; i++) {
        const x = -100 + Math.random() * (p5.width + 100)
        const maxAugHeight = 40;
        const stopHeightAugment = maxAugHeight + Math.cos(Math.PI * 2 * x / (p5.width / 3)) * maxAugHeight
        particles.push({
          x,
          y: 0,
          size: random(3, 8),
          spd: random(30, 50),
          acc: 0,
          stopHeight: random(0, groundTop_ypos + stopHeightAugment * Math.min(1, Math.sqrt(currentTime) / 9)),
        });
      }
    }
  };

  return <Sketch setup={setup as any} draw={draw as any} />;
}

export default SnowSketchONLYSNOW;
