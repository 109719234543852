import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import SEOUL_00 from '../../assets/images/locations/SEOUL_00.jpeg';
import BUSAN_00 from '../../assets/images/locations/BUSAN_00.jpeg';
import BUSAN_01 from '../../assets/images/locations/BUSAN_01.jpeg';
import BUSAN_02 from '../../assets/images/locations/BUSAN_02.jpeg';
import BUSAN_03 from '../../assets/images/locations/BUSAN_03.jpeg';
import BUSAN_04 from '../../assets/images/locations/BUSAN_04.jpeg';
import FUKUOKA_00 from '../../assets/images/locations/FUKUOKA_00.jpeg';
import FUKUOKA_01 from '../../assets/images/locations/FUKUOKA_01.jpeg';
import FUKUOKA_02 from '../../assets/images/locations/FUKUOKA_02.jpeg';
import FUKUOKA_03 from '../../assets/images/locations/FUKUOKA_03.jpeg';
import FUKUOKA_04 from '../../assets/images/locations/FUKUOKA_04.jpeg';
import FUKUOKA_05 from '../../assets/images/locations/FUKUOKA_05.jpeg';
import FUKUOKA_06 from '../../assets/images/locations/FUKUOKA_06.jpeg';
import FUKUOKA_07 from '../../assets/images/locations/FUKUOKA_07.jpeg';
import FUKUOKA_08 from '../../assets/images/locations/FUKUOKA_08.jpeg';
import YG_00 from '../../assets/images/locations/YG_00.jpeg';
import YG_01 from '../../assets/images/locations/YG_01.jpeg';
import YG_02 from '../../assets/images/locations/YG_02.jpeg';
import YG_03 from '../../assets/images/locations/YG_03.jpeg';
import YG_04 from '../../assets/images/locations/YG_04.jpeg';
import YG_05 from '../../assets/images/locations/YG_05.jpeg';
import M_00 from '../../assets/images/locations/M_00.jpeg';
import M_01 from '../../assets/images/locations/M_01.jpeg';
import M_02 from '../../assets/images/locations/M_02.jpeg';
import M_03 from '../../assets/images/locations/M_03.jpeg';
import M_04 from '../../assets/images/locations/M_04.jpeg';
import M_05 from '../../assets/images/locations/M_05.jpeg';
import M_06 from '../../assets/images/locations/M_06.jpeg';
import M_07 from '../../assets/images/locations/M_07.jpeg';
import M_08 from '../../assets/images/locations/M_08.jpeg';
import M_09 from '../../assets/images/locations/M_09.jpeg';
import GORGE_00 from '../../assets/images/locations/GORGE_00.jpeg';
import GORGE_01 from '../../assets/images/locations/GORGE_01.jpeg';
import GORGE_02 from '../../assets/images/locations/GORGE_02.jpeg';
import GORGE_03 from '../../assets/images/locations/GORGE_03.jpeg';
import GORGE_04 from '../../assets/images/locations/GORGE_04.jpeg';
import GORGE_05 from '../../assets/images/locations/GORGE_05.jpeg';
import GORGE_06 from '../../assets/images/locations/GORGE_06.jpeg';
import GORGE_07 from '../../assets/images/locations/GORGE_07.jpeg';
import GORGE_08 from '../../assets/images/locations/GORGE_08.jpeg';
import GORGE_09 from '../../assets/images/locations/GORGE_09.jpeg';
import GORGE_10 from '../../assets/images/locations/GORGE_10.jpeg';
import YUFU_00 from '../../assets/images/locations/YUFU_00.jpeg';
import YUFU_01 from '../../assets/images/locations/YUFU_01.jpeg';
import YUFU_02 from '../../assets/images/locations/YUFU_02.jpeg';
import YUFU_03 from '../../assets/images/locations/YUFU_03.jpeg';
import YUFU_04 from '../../assets/images/locations/YUFU_04.jpeg';
import YUFU_05 from '../../assets/images/locations/YUFU_05.jpeg';
import YUFU_06 from '../../assets/images/locations/YUFU_06.jpeg';
import YUFU_07 from '../../assets/images/locations/YUFU_07.jpeg';
import YUFU_08 from '../../assets/images/locations/YUFU_08.jpeg';
import YUFU_09 from '../../assets/images/locations/YUFU_09.jpeg';
import YUFU_10 from '../../assets/images/locations/YUFU_10.jpeg';
import YUFU_11 from '../../assets/images/locations/YUFU_11.jpeg';
import YUFU_12 from '../../assets/images/locations/YUFU_12.jpeg';
import YUFU_13 from '../../assets/images/locations/YUFU_13.jpeg';
import KYOTO_00 from '../../assets/images/locations/KYOTO_00.jpeg';
import KYOTO_01 from '../../assets/images/locations/KYOTO_01.jpeg';
import KYOTO_02 from '../../assets/images/locations/KYOTO_02.jpeg';
import KYOTO_03 from '../../assets/images/locations/KYOTO_03.jpeg';
import KYOTO_04 from '../../assets/images/locations/KYOTO_04.jpeg';
import KYOTO_05 from '../../assets/images/locations/KYOTO_05.jpeg';
import KYOTO_06 from '../../assets/images/locations/KYOTO_06.jpeg';
import KYOTO_07 from '../../assets/images/locations/KYOTO_07.jpeg';
import KYOTO_08 from '../../assets/images/locations/KYOTO_08.jpeg';
import KYOTO_09 from '../../assets/images/locations/KYOTO_09.jpeg';
import KYOTO_10 from '../../assets/images/locations/KYOTO_10.jpeg';
import TAKA_00 from '../../assets/images/locations/TAKA_00.jpeg';
import TAKA_01 from '../../assets/images/locations/TAKA_01.jpeg';
import TAKA_02 from '../../assets/images/locations/TAKA_02.jpeg';
import TAKA_03 from '../../assets/images/locations/TAKA_03.jpeg';
import TAKA_04 from '../../assets/images/locations/TAKA_04.jpeg';
import TAKA_05 from '../../assets/images/locations/TAKA_05.jpeg';
import TAKA_06 from '../../assets/images/locations/TAKA_06.jpeg';
import TAKA_07 from '../../assets/images/locations/TAKA_07.jpeg';
import TAKA_08 from '../../assets/images/locations/TAKA_08.jpeg';
import TAKA_09 from '../../assets/images/locations/TAKA_09.jpeg';
import TAKA_10 from '../../assets/images/locations/TAKA_10.jpeg';
import TAKA_11 from '../../assets/images/locations/TAKA_11.jpeg';
import TAKA_12 from '../../assets/images/locations/TAKA_12.jpeg';
import TAKA_13 from '../../assets/images/locations/TAKA_13.jpeg';
import TAKA_14 from '../../assets/images/locations/TAKA_14.jpeg';
import TAKA_15 from '../../assets/images/locations/TAKA_15.jpeg';
import TOY_00 from '../../assets/images/locations/TOY_00.jpeg';
import TOY_01 from '../../assets/images/locations/TOY_01.jpeg';
import TOY_02 from '../../assets/images/locations/TOY_02.jpeg';
import TOY_03 from '../../assets/images/locations/TOY_03.jpeg';
import TOY_04 from '../../assets/images/locations/TOY_04.jpeg';
import TOY_05 from '../../assets/images/locations/TOY_05.jpeg';
import TOY_06 from '../../assets/images/locations/TOY_06.jpeg';
import TOY_07 from '../../assets/images/locations/TOY_07.jpeg';
import TOY_08 from '../../assets/images/locations/TOY_08.jpeg';
import TOY_09 from '../../assets/images/locations/TOY_09.jpeg';
import TOY_10 from '../../assets/images/locations/TOY_10.jpeg';
import TOY_11 from '../../assets/images/locations/TOY_11.jpeg';
import TOK_00 from '../../assets/images/locations/TOK_00.jpeg';
import TOK_01 from '../../assets/images/locations/TOK_01.jpeg';
import TOK_02 from '../../assets/images/locations/TOK_02.jpeg';
import TOK_03 from '../../assets/images/locations/TOK_03.jpeg';
import TOK_04 from '../../assets/images/locations/TOK_04.jpeg';
import TOK_05 from '../../assets/images/locations/TOK_05.jpeg';
import TOK_06 from '../../assets/images/locations/TOK_06.jpeg';
import TOK_07 from '../../assets/images/locations/TOK_07.jpeg';
import TOK_08 from '../../assets/images/locations/TOK_08.jpeg';
import TOK_09 from '../../assets/images/locations/TOK_09.jpeg';
import TOK_10 from '../../assets/images/locations/TOK_10.jpeg';
import TOK_11 from '../../assets/images/locations/TOK_11.jpeg';
import KUR_00 from '../../assets/images/locations/KUR_00.jpeg';
import KUR_01 from '../../assets/images/locations/KUR_01.jpeg';
import KUR_02 from '../../assets/images/locations/KUR_02.jpeg';
import KUR_03 from '../../assets/images/locations/KUR_03.jpeg';
import KUR_04 from '../../assets/images/locations/KUR_04.jpeg';
import HIR_00 from '../../assets/images/locations/HIR_00.jpeg';
import HIR_01 from '../../assets/images/locations/HIR_01.jpeg';
import HIR_02 from '../../assets/images/locations/HIR_02.jpeg';
import HIR_03 from '../../assets/images/locations/HIR_03.jpeg';
import HIR_04 from '../../assets/images/locations/HIR_04.jpeg';
import HIR_05 from '../../assets/images/locations/HIR_05.jpeg';
import HIR_06 from '../../assets/images/locations/HIR_06.jpeg';
import HIR_07 from '../../assets/images/locations/HIR_07.jpeg';
import HIR_08 from '../../assets/images/locations/HIR_08.jpeg';
import HIR_09 from '../../assets/images/locations/HIR_09.jpeg';
import HIR_10 from '../../assets/images/locations/HIR_10.jpeg';
import HIR_11 from '../../assets/images/locations/HIR_11.jpeg';
import HIR_12 from '../../assets/images/locations/HIR_12.jpeg';
import traincutout from '../../assets/images/alttrain.png';
import beginningtolook from '../../assets/audio/beginningtolook.mp3';
import lastchristmas from '../../assets/audio/lastchristmas.mp3';
import thischristmas from '../../assets/audio/this.mp3';
import newyears from '../../assets/audio/newyears.mp3';
import nonsense from '../../assets/audio/nonsense.mp3';
import treefarm from '../../assets/audio/treefarm.mp3';
import underneath from '../../assets/audio/underneath.mp3';
import wonderland from '../../assets/audio/wonderland.mp3';
import alliwant from '../../assets/audio/alliwant.mp3';
import ImageCarousel from './Carousel';
import SnowSketchONLYSNOW from '../SnowSketchONLYSNOW';
import AudioPlayer from './AudioPlayer';

export default function Main() {
    return <div className='w-screen h-screen bg-black flex justify-center items-center overflow-hidden'>
        <Playlist />
    </div>
}

type Data = {
    songName: string
    placeName: string
    audioFile?: string
    images: string[]
}

const dataset: Data[] = [
    {
        songName: "Last Christmas", placeName: "Korea & Fukuoka",
        audioFile: lastchristmas,
        images: [
            SEOUL_00, BUSAN_00, BUSAN_01, BUSAN_02, BUSAN_03, BUSAN_04,
            FUKUOKA_00, FUKUOKA_01, FUKUOKA_02, FUKUOKA_03, FUKUOKA_04, FUKUOKA_05, FUKUOKA_06, FUKUOKA_07, FUKUOKA_08
        ] // 15
    },
    {
        songName: "It's beginning to look a lot like kellymas", placeName: "Yanagawa & Miyazaki",
        audioFile: beginningtolook,
        images: [
            YG_00, YG_01, YG_02, YG_03, YG_04, YG_05,
            M_00, M_01, M_02, M_03, M_04, M_05, M_06, M_07, M_08, M_09
        ] // 16
    },
    {
        songName: "Underneath the tree", placeName: "Takachiho",
        audioFile: underneath,
        images: [
            GORGE_00, GORGE_01, GORGE_02, GORGE_03, GORGE_04, GORGE_05, GORGE_06, GORGE_07, GORGE_08, GORGE_09, GORGE_10
        ] // 11
    },
    {
        songName: "All I want for Christmas is you", placeName: "Yufiun",
        audioFile: alliwant,
        images: [
            YUFU_00, YUFU_01, YUFU_02, YUFU_03, YUFU_04, YUFU_05, YUFU_06, YUFU_07, YUFU_08, YUFU_09, YUFU_10, YUFU_11, YUFU_12, YUFU_13
        ] // 14
    },
    {
        songName: "A Nonsense Christmas", placeName: "Kyoto",
        audioFile: nonsense,
        images: [
            KYOTO_00, KYOTO_01, KYOTO_02, KYOTO_03, KYOTO_04, KYOTO_05, KYOTO_06, KYOTO_07, KYOTO_08, KYOTO_09, KYOTO_10
        ] // 11
    },
    {
        songName: "Christmas Tree Farm (old timey version)", placeName: "Takayama & Shirakawa-go",
        audioFile: treefarm,
        images: [
            TAKA_00, TAKA_01, TAKA_02, TAKA_03, TAKA_04, TAKA_05, TAKA_06, TAKA_07, TAKA_08, TAKA_09, TAKA_10, TAKA_11, TAKA_12, TAKA_13, TAKA_14, TAKA_15
        ] // 16
    },
    {
        songName: "Winter Wonderland", placeName: "Hirayu",
        audioFile: wonderland,
        images: [
            HIR_00, HIR_01, HIR_02, HIR_03, HIR_04, HIR_05, HIR_06, HIR_07, HIR_08, HIR_09, HIR_10, HIR_11, HIR_12,
            
        ] // 13
    },
    {
        songName: "This Chistmas", placeName: "Kanazawa, Toyama & Kurobe",
        audioFile: thischristmas,
        images: [
            TOY_00, TOY_01, TOY_02, TOY_03, TOY_04, TOY_05, TOY_06, TOY_07, TOY_08, TOY_09, TOY_10, TOY_11,
            KUR_00, KUR_01, KUR_02, KUR_03, KUR_04
        ] // 17
    },
    {
        songName: "is it new years yet?", placeName: "Tokyo",
        audioFile: newyears,
        images: [
            TOK_00, TOK_01, TOK_02, TOK_03, TOK_04, TOK_05, TOK_06, TOK_07, TOK_08, TOK_09, TOK_10, TOK_11
        ] // 12
    },
]

function Playlist() {
    const [displayState, setDisplayState] = useState<'jukebox' | 'room' | 'window'>('room');

    const toggleZoom = () => {
        setDisplayState(displayState === 'jukebox' ? 'room' : displayState === 'room' ? 'window' : 'jukebox');
    };

    const [currentIndex, setCurrentIndex] = useState<number>(0)

    const [hasTappedToBegin, setHasTappedToBegin] = useState<boolean>(false)

    const animationVariants = {
        jukebox: {
            scale: 3.5,
            translateY: "0%",
            transition: {
                duration: 1,
                type: "spring",
                damping: 30, // More damping for a less bouncy effect
                mass: 4
            },
        },
        room: {
            scale: 1,
            translateY: "0%",
            transition: {
                duration: 1,
                type: "spring",
                damping: 40, // Even more damping when zooming out
                mass: 2 // Less mass for a quicker response
            },
        },
        window: {
            scale: 2.8,
            translateY: "50%",
            transition: {
                duration: 1,
                type: "spring",
                damping: 40, // Even more damping when zooming out
                mass: 2 // Less mass for a quicker response
            },
        }
    }

    const hasMouseMoved = useMouseActivity()

    const { isWider, windowHeight } = useWindowDimensions()

    const style = isWider ? { height: windowHeight, width: windowHeight, paddingBottom: 'inherit' } : {}

    return (
        <div style={style} className='relative aspect-square w-[90%] pb-[90%]'>
            <Layer nointeract> {/* Scenery */}
                <motion.div
                    animate={{
                        scale: displayState === 'jukebox' ? 1.2 : 1,
                        transition: animationVariants[displayState].transition,
                    }}
                    className='origin-bottom w-full h-full'>
                    <ImageCarousel cls='-translate-y-32' images={dataset[currentIndex].images} />
                </motion.div>
            </Layer>

            <Layer nointeract>
                <div className='ml-[-22%] w-screen h-screen overflow-hidden'>
                    <SnowSketchONLYSNOW />
                </div>
            </Layer>

            <Layer nointeract> {/* Blur */}
                <motion.div
                    animate={{
                        opacity: displayState === 'jukebox' ? 0.95 : 0,
                        transition: animationVariants[displayState].transition,
                    }}
                    className='w-full h-full backdrop-blur-lg' />
            </Layer>

            <Layer nointeract> {/* Blur */}
                <motion.div
                    animate={{
                        opacity: displayState === 'room' ? 0.95 : 0,
                        transition: animationVariants[displayState].transition,
                    }}
                    className='w-full h-full backdrop-blur-sm' />
            </Layer>

            <Layer nointeract>
                <AnimatePresence initial={true}>
                    {displayState === 'room' &&
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.8 }}
                            className='w-full h-full'>
                            <div className='bg-black absolute top-0 bottom-0 right-[100%] w-full' />
                            <div className='bg-black absolute top-0 bottom-0 left-[100%] w-full' />
                        </motion.div>
                    }
                </AnimatePresence>
            </Layer>

            <Layer> {/* Train Interior */}
                <motion.div
                    variants={animationVariants as any}
                    animate={displayState}
                    className="w-full"
                    onClick={toggleZoom} // Add the toggle function to the onClick event
                >
                    <motion.img
                        variants={{
                            jukebox: { translateY: [0, 0], opacity: [1, 1] },
                            room: { translateY: [-8, 0, -8], opacity: [1, 1] },
                            window: { translateY: [-6, 0, -6], opacity: hasMouseMoved ? [1, 1] : [0.5, 0.5] }
                        }}
                        animate={displayState}
                        transition={{
                            duration: 2, // Duration of the animation
                            repeat: Infinity, // Repeat the animation indefinitely
                            ease: 'easeInOut', // Easing function
                        }}
                        src={traincutout} className="w-full" alt="Train Cutout" />
                </motion.div>
            </Layer>

            <Layer nointeract>
                <motion.div
                    variants={{
                        jukebox: { opacity: 0 },
                        room: { opacity: 1 },
                        window: { opacity: 0 }
                    }}
                    animate={displayState}
                    className='flex flex-col items-center justify-center w-full h-full text-white text-4xl md:text-5xl lg:text-6xl christmas-card'>
                    <div style={{ filter: "drop-shadow(3px 5px 1px rgba(0,0,0,0.8)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5))" }} >{dataset[currentIndex].placeName}</div>
                    <div style={{ filter: "drop-shadow(3px 5px 1px rgba(0,0,0,0.8)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5))" }} >x</div>
                    <div style={{ filter: "drop-shadow(3px 5px 1px rgba(0,0,0,0.8)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5))" }} >{dataset[currentIndex].songName}</div>
                    <div className='h-[45%]' />
                </motion.div>
            </Layer>

            {dataset[currentIndex].audioFile !== undefined &&
                <AudioPlayer src={dataset[currentIndex].audioFile!} readyToPlay={hasTappedToBegin} onFinished={() => {setCurrentIndex(prev => (prev + 1) % dataset.length)}}/>
            }
            {displayState === 'jukebox' &&
                <Layer>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3, duration: 0.6 }}
                        className='flex flex-col w-full h-full items-center justify-center'
                        onClick={() => {
                            setDisplayState('window')
                        }}>
                        <div className="h-20" />
                        <div className='w-[85%] 
                         py-12 rounded-xl
                         bg-gradient-to-br from-slate-900/95 to-black/95 
                         backdrop-blur-lg 
                         border-white/40 border-2 
                         flex flex-col items-center space-y-6'
                            onClick={(e) => { e.stopPropagation() }}
                            style={{ boxShadow: "rgb(0 0 0 / 64%) 0px 8px 14px 4px" }}>
                            <div className='text-gray-300 text-4xl christmas-card'>
                                kellymas jukebox
                            </div>
                            <div className='space-x-4 flex christmas-card text-white text-3xl md:text-3xl lg:text-4xl'>
                                <div className='relative w-full p-8 flex flex-col justify-center overflow-hidden'>
                                    <div
                                        style={{ maskImage: "linear-gradient(to bottom, transparent, transparent 20%, black)" }}
                                        className='bg-red-800/80 text-center border-red-50/80 border-2 shadow-2xl rounded px-12 py-3 -mt-4'
                                    >{dataset[(currentIndex - 1 + dataset.length) % dataset.length].songName}</div>
                                    <div className='bg-red-800/80 text-center border-red-50/80 border-2 shadow-2xl rounded px-12 py-3 my-4'>

                                        <div className='text-xl md:text-2xl mt-1'>
                                            {dataset[currentIndex].placeName}
                                        </div>
                                        <div className='text-xl md:text-2xl -mt-1'>
                                            x
                                        </div>
                                        <div>
                                            {dataset[currentIndex].songName}
                                        </div>
                                    </div>
                                    <div
                                        style={{ maskImage: "linear-gradient(to top, transparent, transparent 20%, black)" }}
                                        className='bg-red-800/80 text-center border-red-50/80 border-2 shadow-2xl rounded px-12 py-3 -mb-4'
                                    >{dataset[(currentIndex + 1) % dataset.length].songName}</div>
                                </div>
                            </div>
                            <div className='text-white text-xl md:text-2xl lg:text-3xl christmas-card'>
                                <button className='bg-green-900/50 shadow-2xl backdrop-blur-lg rounded px-12 py-1'
                                    onClick={() => {
                                        setCurrentIndex((currentIndex + 1) % dataset.length)
                                        setDisplayState('room')
                                    }}
                                >Next Song</button>
                            </div>
                        </div>
                    </motion.div>
                </Layer>
            }


            {!hasTappedToBegin &&

                <Layer>
                    <motion.div
                        className='cursor-pointer flex flex-col w-full h-full items-center justify-center backdrop-blur-md'
                        onClick={() => {
                            setHasTappedToBegin(true)
                        }}>
                        <div className="h-20" />
                        <div className='text-white text-4xl christmas-card'>Kellymas Jukebox</div>
                        <div className='text-white text-lg'>Tap anywhere to change the song</div>
                        <div className='text-white text-lg'>Desktop only please!</div>
                        <div className='h-8'/>
                        
                        <div className='text-white text-lg'>Tap to Begin</div>
                    </motion.div>
                </Layer>
            }
        </div >
    );
}


function Layer({ children, nointeract = false }: { children: React.ReactNode, nointeract?: boolean }) {
    return <div style={{ pointerEvents: nointeract ? 'none' : 'auto' }} className='absolute top-0 left-0 w-full h-full'>
        {children}
    </div>
}


function useWindowDimensions() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        // Function to update window dimensions
        function updateWindowDimensions() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        }

        // Initial call to set window dimensions
        updateWindowDimensions();

        // Event listener to update window dimensions on window resize
        window.addEventListener('resize', updateWindowDimensions);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);

    // Determine whether the window is wider or taller
    const isWider = windowWidth > windowHeight;
    const isTaller = windowHeight > windowWidth;

    return { windowWidth, windowHeight, isWider, isTaller };
}

const useMouseActivity = () => {
    const [isActive, setIsActive] = useState(false);
    const timeLimit = 5000; // 10 seconds in milliseconds

    useEffect(() => {
        let timer: NodeJS.Timeout;

        const mouseMoved = () => {
            clearTimeout(timer);
            setIsActive(true);
            timer = setTimeout(() => setIsActive(false), timeLimit);
        };

        window.addEventListener('mousemove', mouseMoved);

        return () => {
            window.removeEventListener('mousemove', mouseMoved);
            clearTimeout(timer);
        };
    }, []);

    return isActive;
};
