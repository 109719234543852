import React, { useEffect, useState } from "react";
import P1 from "../assets/images/miffy/1.png";
import P2 from "../assets/images/miffy/2.jpeg";
import P3 from "../assets/images/miffy/3.jpeg";
import P4 from "../assets/images/miffy/4.jpeg";
import P5 from "../assets/images/miffy/5.jpeg";
import P6 from "../assets/images/miffy/6.jpeg";
import P7 from "../assets/images/miffy/7.jpeg";
import P8 from "../assets/images/miffy/8.jpeg";
import P9 from "../assets/images/miffy/9.jpeg";
import P10 from "../assets/images/miffy/10.jpeg";
import StringImg from "../assets/images/string.png";
import { random } from "../core/utils";

// angle always starts at zero (and move through the center)

type Object = {
  x: number;
  y: number;
  image: string;
  height: number;
  angle: number;
  scale: number;
};

function Hoverable({
  active = true,
  onAdd,
  children,
  externalEditing,
}: {
  active: boolean;
  children: any;
  onAdd?: (count: number) => void;
  externalEditing?: boolean;
}) {
  // is the mouse over the whole canvas
  const [isHover, setIsHover] = useState(false);
  // is the canvas in edit mode (true?)
  const [editing, setEditing] = useState(true);

  const miffies = [P1, P2, P3, P4, P5, P6, P7, P8, P9, P10];

  useEffect(() => {
    if (externalEditing !== undefined && externalEditing !== editing) {
      setEditing(externalEditing);
    }
  }, [editing, externalEditing]);

  // Switch between edit and view mode
  useEffect(() => {
    const onKeyPressCallback = (e: KeyboardEvent) => {
      if (e.code === "Enter") {
        setEditing(!editing);
      }
    };

    window.addEventListener("keypress", onKeyPressCallback);

    return () => {
      window.removeEventListener("keypress", onKeyPressCallback);
    };
  }, [editing]);

  const [objects, setObjects] = useState<Object[]>([]);

  const [nextObject, setNextObject] = useState<Object | undefined>(undefined);

  const [nextSize, setNextSize] = useState<number | undefined>(undefined);
  const [nextAngle, setNextAngle] = useState<number | undefined>(undefined);
  const [nextAngleBaseline, setNextAngleBaseline] = useState<
    number | undefined
  >(undefined);

  const beginPlacingNext = (e: {
    currentTarget: HTMLElement;
    clientX: number;
    clientY: number;
  }) => {
    setIsHover(true);
    if (!editing) {
      return;
    }
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const xFromCenter = Math.abs(x - rect.width / 2);
    const yFromCenter = Math.abs(y - rect.height / 2);
    const distance = Math.sqrt(
      Math.pow(xFromCenter, 2) + Math.pow(yFromCenter, 2)
    );

    setNextObject({
      x,
      y,
      image: miffies[Math.floor(random(0, 10))],
      height: distance / 4.0,
      scale: 0,
      angle: 0,
    });
    setNextSize(42.0);
    setNextAngle(0.0);
  };

  const maybeUpdateEdit = (e: {
    currentTarget: HTMLElement;
    clientX: number;
    clientY: number;
  }) => {
    if (!nextObject) {
      return;
    }
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const xFromOrigin = x - nextObject.x;
    const yFromOrigin = y - nextObject.y;
    const distance = Math.sqrt(
      Math.pow(Math.abs(xFromOrigin), 2) + Math.pow(Math.abs(yFromOrigin), 2)
    );
    setNextSize(Math.max(distance, 42));

    const angRad = Math.atan2(yFromOrigin, xFromOrigin) % (2.0 * Math.PI);
    const angDeg = (angRad / (2 * Math.PI)) * 360;

    if (distance > 20) {
      setNextAngle(angDeg - (nextAngleBaseline ?? 0));
    } else {
      setNextAngleBaseline(angDeg);
    }
  };

  const clearEdit = () => {
    setIsHover(false);
    if (nextObject) {
      if (onAdd) {
        onAdd(objects.length + 1);
      }
      setObjects([
        ...objects,
        {
          ...nextObject,
          scale: nextSize ?? 0,
          angle: nextObject.angle + (nextAngle ?? 0),
        },
      ]);
    }
    setNextObject(undefined);
  };

  const isRaised = !editing && isHover && active;
  return (
    <div
      onMouseLeave={() => {
        clearEdit();
      }}
      className={`relative h-fit w-fit ${editing ? "cursor-pointer" : ""}`}
      style={{
        perspective: "150px",
        perspectiveOrigin: `center center`,
      }}
      onTouchStart={(event) => {
        event.preventDefault();
        beginPlacingNext({
          currentTarget: event.currentTarget,
          clientX: event.changedTouches[0].clientX,
          clientY: event.changedTouches[0].clientY,
        });
      }}
      onTouchEnd={clearEdit}
      onTouchMove={(event) => {
        event.preventDefault();
        maybeUpdateEdit({
          currentTarget: event.currentTarget,
          clientX: event.changedTouches[0].clientX,
          clientY: event.changedTouches[0].clientY,
        });
      }}
      onMouseDown={beginPlacingNext}
      onMouseUp={clearEdit}
      onMouseMove={maybeUpdateEdit}
    >
      {React.cloneElement(children, { editing, isHover })}
      {objects.map((object, index) => {
        return (
          <ObjectRenderer
            key={index}
            object={object}
            isRaised={isRaised}
            editing={editing}
          />
        );
      })}
      {nextObject && (
        <ObjectRenderer
          object={nextObject}
          overrideSize={nextSize}
          overrideAngle={nextAngle}
          editing={editing}
        />
      )}
    </div>
  );
}

function ObjectRenderer({
  object: { x, y, height, angle, scale, image },
  overrideSize,
  overrideAngle,
  editing,
  isRaised = false,
}: {
  object: Object;
  overrideSize?: number;
  overrideAngle?: number;
  editing: boolean;
  isRaised?: boolean;
}) {
  const size = overrideSize ?? Math.max(scale, 42);
  const rotation = angle + (overrideAngle ?? 0);

  return (
    <div
      className={`bg-yellow-500 shadow-xl absolute top-0 left-0 rounded-full ${
        editing ? "" : "transition duration-200"
      } pointer-events-none`}
      style={{
        backgroundPosition: "center",
        width: `${size}px`,
        height: `${size}px`,
        transform: `translate3d(${x - size / 2}px, ${y - size / 2}px, ${
          isRaised ? height : 0
        }px) rotate(${rotation}deg)`,
      }}
    >
      <div className="relative w-full h-full">
        <div className="absolute w-full h-full overflow-hiden rounded-full flex flex-col items-center">
          <img
            src={StringImg}
            alt="string"
            style={{
              transform: `translate(0px, -${size / 3}px)`,
              width: `${size / 2}px`,
              height: `${size / 2}px`,
            }}
          />
        </div>
        <div className="absolute w-full h-full overflow-hiden rounded-full">
          <img
            src={image}
            alt="ornament"
            className="object-contain rounded-full w-full h-full"
          />
        </div>
      </div>
    </div>
  );
}

export default Hoverable;
