import Lakefront from "../assets/images/lakefront.png";
function Bye() {
  return (
    <div className="relative">
      <div className="absolute w-screen h-screen top-0 left-0 flex items-center justify-center">
        <img
          className="max-h-screen"
          src={Lakefront}
          alt="best house on the lake"
        />
      </div>
      <div className="absolute p-4 rounded-br bg-white">
        <p className="text-gray-800">
          okay
          <span className=""> happy kellymas </span>
          <span className="text-red-700">
            <a href="https://open.spotify.com/track/7r7j12XctfJqZWXsy1Zq9L?go=1&sp_cid=9ebdfbee8d0ff1671704fcb815be4fd2&t=4&utm_source=embed_player_p&utm_medium=desktop">
              merry christmas{" "}
            </a>
          </span>
          <span className="">happy birthday </span>
          <span className="">bye</span>
        </p>
        <p className="text-xs text-gray-200 my-0.5">
          that gingerbread house was kickass btw
        </p>
        {/* <a
          className="underline text-green-600"
          href="https://open.spotify.com/track/7r7j12XctfJqZWXsy1Zq9L?go=1&sp_cid=9ebdfbee8d0ff1671704fcb815be4fd2&t=4&utm_source=embed_player_p&utm_medium=desktop"
        >
          play me
        </a> */}
      </div>
    </div>
  );
}

export default Bye;
