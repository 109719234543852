import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const ImageCarousel = ({ images, interval = 6000, cls }: { cls?: string, images: string[], interval?: number }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, interval);

        return () => clearInterval(timer);
    }, [images.length, interval]);

    useEffect(() => {
        setCurrentImageIndex(0);
    }, [images])

    const imageVariants = {
        enter: { opacity: 0, scale: 0.7, translateY: -50 },
        center: { opacity: 1, scale: 1, translateY: 0 },
        exit: { opacity: 0, scale: 2, translateY: 200 },
    };

    return (
        <div className='relative w-full h-full'>
            <div className='absolute top-0 left-[-34%] w-screen h-screen'>
                <img src={images[currentImageIndex]} className={`w-full h-full blur-xl ${cls}`} alt="background"/>
            </div>
            <AnimatePresence initial={false}>
                <div key={currentImageIndex}
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 60,
                        right: 60,
                    }} className={`flex items-center justify-center h-full ${cls}`}>
                    <motion.img
                        key={currentImageIndex}
                        src={images[currentImageIndex]}
                        alt={`Slide ${currentImageIndex}`}
                        variants={imageVariants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{ duration: 2 }}
                    />
                </div>
            </AnimatePresence>
        </div>
    );
};

export default ImageCarousel;
