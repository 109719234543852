import React, { useEffect, useRef } from 'react';

const AudioPlayer = ({ src, readyToPlay, onFinished }: { src: string, readyToPlay: boolean, onFinished?: () => void }) => {
    const audioRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        const audio = audioRef.current;
        if (!audio) return;

        const handlePlay = () => console.log('Audio play event triggered');
        const handlePause = () => console.log('Audio pause event triggered');
        const handleEnded = () => {
            console.log('Audio ended event triggered');
            if (onFinished) {
                onFinished();
            }
        };
        const handleError = (e: any) => console.error('Audio error event triggered', e);

        const togglePlayPause = (event: any) => {
            if (event.code === 'Space') {
                if (audio.paused) {
                    audio.play();
                } else {
                    audio.pause();
                }
            }
        };

        // Add event listeners for audio events
        audio.addEventListener('play', handlePlay);
        audio.addEventListener('pause', handlePause);
        audio.addEventListener('ended', handleEnded);
        audio.addEventListener('error', handleError);

        // Play audio if ready
        if (readyToPlay) {
            audio.play();
        }

        // Add global event listener for keyup
        window.addEventListener('keyup', togglePlayPause);

        // Cleanup function
        return () => {
            audio.removeEventListener('play', handlePlay);
            audio.removeEventListener('pause', handlePause);
            audio.removeEventListener('ended', handleEnded);
            audio.removeEventListener('error', handleError);
            window.removeEventListener('keyup', togglePlayPause);
        };
    }, [readyToPlay, onFinished]);

    useEffect(() => {
        const audio = audioRef.current;
        if (!audio) return;

        // Update audio source if changed
        audio.src = src;
    }, [src]);

    return (
        <audio ref={audioRef} autoPlay={readyToPlay}>
            <source src={src} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    );
};

export default AudioPlayer;
