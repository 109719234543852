import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./pages/Home";
import Snow from "./pages/Snow";
import Greg from "./pages/Greg";
import Bye from "./pages/Bye";
import Tree from "./pages/Tree";
import Mailbox from "./pages/mailbox/Mailbox"
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Playlist from "./pages/playlist/Playlist";
import MailboxJNine from "./pages/mailbox/MailboxJNine";

const router = createBrowserRouter([
  {
    path: "2022",
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "wtf",
        element: <Greg />,
      },
      {
        path: "decorate",
        element: <Tree />,
      },
      {
        path: "bye",
        element: <Bye />,
      },
    ],
  },
  {path: "mailbox",
  element: <Mailbox />,
},
{path: "long-distance",
element: <MailboxJNine />,
},
{path: "playlist",
element: <Playlist />,
},
  {
    path: "",
    element: <Snow />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
