import { useState } from "react";
import { useEffect } from "react";
import TreeImg from "../assets/images/tree.jpg";
import Hoverable from "../components/Hoverable";

function Tree() {
  useEffect(() => {
    document.body.addEventListener("touchmove", (e) => e.preventDefault(), {
      passive: false,
    });
  }, []);

  const [congrats, setCongrats] = useState(false);
  const [editing, setEditing] = useState(true);

  return (
    <div className="absolute top-0 left-0 bottom-0 right-0 overflow-hidden">
      <div className="relative h-screen overflow-hidden">
        <div className="absolute top-0 left-0 bottom-0 right-0 overflow-hidden flex items-center justify-center">
          <Hoverable
            active={true}
            externalEditing={editing}
            onAdd={(count) => {
              if (count > 3) {
                setCongrats(true);
              }
            }}
          >
            <img
              className="max-h-screen pointer-events-none select-none"
              src={TreeImg}
              alt="tree"
            />
          </Hoverable>
        </div>

        <div className="absolute flex flex-col p-4 text-green-900 bg-white rounded-br-lg">
          <span className="text-sm">looks like greg dropped off a tree</span>
          {congrats ? (
            <>
              <span className="">i assume it looks great</span>
              <span className="text-xs text-red-900">(screenshot?)</span>
              <a
                href="bye"
                className="text-lg px-2 mt-1 rounded text-red-900 w-fit border-2 border-red-700"
              >
                →
              </a>
            </>
          ) : (
            <>
              <span>might as well decorate it?</span>
              <span className="text-xs text-red-900 ">(press and drag)</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default Tree;
