import { useState } from "react";
import Santa from "../assets/images/santa.webp";

function Greg() {
  const disabledStyle = { filter: "grayscale(0.9)", opacity: 0.65 };

  const transforms = [
    "",
    "translate(40%, -40%) scale(0.5) rotate(20deg)",
    "translate(-30%, 60%) scale(0.6) rotate(-10deg)",
    "translate(-70%, -10%) scale(0.4) rotate(70deg)",
    "translate(35%, 28%) scale(0.9) rotate(310deg)",
    "scale(0.7) rotate(2deg)",
    "scale(0.75) rotate(8deg)",
    "scale(0.8) rotate(16deg)",
    "scale(0.85) rotate(32deg)",
    "scale(0.9) rotate(52deg)",
    "translate(0%, -50%) scale(2.6) rotate(180deg)",
  ];
  const [clicks, setClicks] = useState(0);

  return (
    <div className="relative">
      {transforms.slice(0, clicks + 1).map((transform, index) => {
        const active = index === clicks;
        return (
          <div className="absolute h-screen w-screen flex flex-col items-center justify-center p-4">
            <img
              src={Santa}
              onClick={
                active
                  ? () => {
                      if (clicks >= transforms.length - 1) {
                        window.location.href = "decorate";
                      } else {
                        setClicks(clicks + 1);
                      }
                    }
                  : undefined
              }
              alt="Santa Claus (greg)"
              className="h-3/5 object-scale-down cursor-pointer"
              style={
                active
                  ? { transform: transform }
                  : { transform: transform, ...disabledStyle }
              }
            />
          </div>
        );
      })}
    </div>
  );
}
export default Greg;
