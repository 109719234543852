import { useState } from "react";
import image_red from "../assets/images/red.png";
import ConfettiExplosion from "react-confetti-explosion";

function Home() {
  const [numClicks, setNumClicks] = useState(0);
  const [bounce, setBounce] = useState<number>(1);

  const messages = ["could it be?", "could it truly be ??", "is it ..."];

  const centerMessage = "KELLYMAS???";

  const clickedImg = () => {
    if (numClicks > messages.length) {
      window.location.href = "2022/wtf";
      return;
    }

    if (numClicks === messages.length) {
      setIsExploding(true);
    }
    setNumClicks(numClicks + 1);
    setBounce(1.1 + numClicks * 0.05);
    setTimeout(
      () => {
        setBounce(1);
      },
      numClicks > 2 ? 350 : 250
    );
  };

  const [isExploding, setIsExploding] = useState(false);

  return (
    <div className="relative h-screen">
      <div className="absolute top-0 left-0 bottom-0 right-0 overflow-hidden pointer-events-none">
        {isExploding && (
          <div className="absolute right-1/2 left-1/2 top-1/2 bottom-1/2">
            <ConfettiExplosion
              force={0.8}
              duration={8000}
              particleCount={100}
              height={1400}
              width={1400}
            />
          </div>
        )}
      </div>
      <div className="absolute w-screen h-screen top-0 left-0 flex items-center justify-center">
        <img
          onClick={clickedImg}
          className="cursor-pointer max-h-screen transition-all duration-200"
          style={{
            transform: bounce > 1 ? `scale(${bounce})` : "",
            transitionDuration: numClicks > 2 ? "500" : "300",
          }}
          src={image_red}
          alt="Red Present"
        />
      </div>
      <div className="relative p-2">
        {messages
          .slice(0, Math.min(numClicks, messages.length))
          .map((message) => {
            return (
              <>
                <span className="text-2xl font-light">{message}</span>
                <br />
              </>
            );
          })}
      </div>
      {numClicks > messages.length && (
        <>
          <div className="absolute w-screen h-screen top-0 left-0 flex items-center justify-center pointer-events-none">
            <span className="text-5xl md:text-7xl lg:text-8xl font-semibold text-white pr-1 pb-0.5 md:pr-2 md:pb-1.5">
              {centerMessage}
            </span>
          </div>
          <div className="absolute w-screen h-screen top-0 left-0 flex items-center justify-center pointer-events-none">
            <span className="text-5xl md:text-7xl lg:text-8xl font-semibold text-black pl-1 pt-0.5 md:pl-2 md:pt-1.5">
              {centerMessage}
            </span>
          </div>
          <div className="absolute w-screen h-screen top-0 left-0 flex items-center justify-center pointer-events-none">
            <span className="text-5xl md:text-7xl lg:text-8xl font-semibold text-green-900">
              {centerMessage}
            </span>
          </div>
        </>
      )}
      <div className="relative hidden">
        <span className="text-4xl font-light">
          kellymas
          <br />
          is here
          <br />
          happiness
          <br />
          and cheer
          <br />
          snowflakes
          <br />
          in the air
          <br />
          carols
          <br />
          everywhere
        </span>
      </div>
    </div>
  );
}
export default Home;
